
<a class="add-btn" role="button" id="addMenu" *ngIf="addFunction != null" (click)="addFunction.emit()" disabled="disableAddFunction"
   [ngClass]="{'no-kebab': dropdown.children.length == 0, 'disabled': disableAddFunction}">
    <i class="fas fa-plus fa-lg"></i>
</a>
<a class="kebab" role="button" id="kebabMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" *ngIf="!(dropdown.children.length == 0)">
    <i class="fas fa-ellipsis-v fa-lg"></i>
</a>
<div class="dropdown-menu shadow-default dropdown-menu-right" aria-labelledby="kebabMenu" #dropdown>
    <ng-content></ng-content>
</div>
