<div class="card p-3">

    <h1 class="mb-4">Terms Of Use</h1>

    <div class="content">
        <p><em>Synectics plc, owner of www.synxcloud.com</em></p>
        <p><b>Please read these terms and conditions carefully before using this site</b></p>
        <p><b>1. Who We are and How to Contact Us</b></p>
        <p>1.1. Our Site is owned and operated by Synectics plc (We, Us, Our). We are registered in England and Wales under company number 01740011 and have Our registered office at Studley Point, 88 Birmingham Road, Studley, Warwickshire, B80 7AS. Our VAT number is 417 06 98 46.</p>
        <p>1.2. We are a public limited company.</p>
        <p>1.3. To contact Us, please email <a href="mailto:contact@synecticsglobal.com"><span class="cloaked_email">contact@synecticsglobal.com</span></a><script type="text/javascript">emailProtector.addCloakedMailto("ep_64e86091", 1);</script></p>
        <p><b>2. These Terms</b></p>
        <p>2.1. This Policy sets out the content standards that apply when you upload content to Our Site, make contact with other users of Our Site, link to our site, or interact with Our Site in any other way.</p>
        <p><b>3. Acceptance of this Policy</b></p>
        <p>3.1. By using Our Site, you confirm that you accept the terms of this Policy and that you agree to comply with them.</p>
        <p>3.2. If you do not agree to this Policy, you must not use Our Site.</p>
        <p>3.3. We recommend that you print a copy of these terms for future reference.</p>
        <p><b>4. Other Terms that Apply</b></p>
        <p>4.1. The following terms also apply to your use of Our Site;</p>
        <p>4.1.1. Our <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">website privacy policy</a> (Website Privacy Policy), which sets out the terms on which We process any personal data We collect from you, or that you provide to Us;</p>
        <p>4.1.2. Our <a href="/terms-of-use" target="_blank" rel="noopener noreferrer">terms of use</a> (Terms of Use), which sets out the permitted uses and prohibited uses of Our Site. When using Our Site, you must comply with these Terms of Use; and</p>
        <p>4.1.3. Our <a href="/cookie-policy" target="_blank" rel="noopener noreferrer">cookie policy</a> (Cookie Policy), which sets out information about the collection of cookie data from Our Site.</p>
        <p><b>5. Changes to this Policy</b></p>
        <p>5.1 We may unilaterally amend this Policy at any time and for any reason from time to time without notification. Every time you wish to use Our Site, please check these terms to ensure you understand Policy that applies at that time.</p>
        <p><b>6. Changes to Our Site</b></p>
        <p>6.1. We may update and change Our Site and its’ content from time to time to reflect changes to Our products, services, information about any of Our companies, Our users’ needs and Our business priorities.</p>
        <p>6.2. We will use Our reasonable endeavours to notify you of any major changes upon your next visit following any significant changes to Our Site but We are not obliged to contact you personally.</p>
        <p><b>7. Prohibited Uses</b></p>
        <p>7.1. You may use Our Site only for lawful purposes. You may not use Our Site:</p>
        <p>7.1.1. in any way that breaches any applicable local, national or international law or regulations;</p>
        <p>7.1.2. In any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect;</p>
        <p>7.1.3. for the purpose of harming or attempting to harm minors in any way;</p>
        <p>7.1.4. to send, knowingly receive, upload, download, use or re-use any material which does not comply with this Policy, our Terms of Use, Privacy Policy or Cookie Policy;</p>
        <p>7.1.5. to transmit, or procure the sending of, any unsolicited or unauthorised advertising or promotional material or any other form of similar solicitation (spam); and</p>
        <p>7.1.6. to knowingly transmit any data, send or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to adversely affect the operation of any computer software or hardware</p>
        <p>7.2. You also agree:</p>
        <p>7.2.1. not to reproduce, duplicate, copy or re-sell any part of Our Site in contravention of the Terms of Use;</p>
        <p>7.2.2. not to access without authority, interfere with, damage or disrupt:</p>
        <p>7.2.2.1. any part of Our Site;</p>
        <p>7.2.2.2. any equipment or network on which Our Site is stored;</p>
        <p>7.2.2.3. any software used in the provision of Our Site; or</p>
        <p>7.2.2.4. any equipment or network or software owned or used by any third party.</p>
        <p><b>8. Interactive Services</b></p>
        <p>8.1. We may from time to time provide other services on Our Site, including, without limitation the use of bulletin boards or other notice boards or contemporaneous news feeds and pages.</p>
        <p>8.2. When We provide any interactive service, We will provide clear information to you about the kind of service offered, if it is moderated and what form of moderation is used (including whether it is human or technical).</p>
        <p>8.3. We will assess any possible risks for users (and in particular, for children) from third parties when they use any interactive service provided on Our Site, and We will decide in each case whether it is appropriate to use moderation of the relevant service (including what kind of moderation to use) in the light of those risks.</p>
        <p>8.4. We are under no obligation to oversee, monitor or moderate any interactive service We provide on Our Site, and we expressly exclude Our liability for any loss or damage arising from the use of any interactive service by a user in contravention of our content standards, whether the service is moderated or not.</p>
        <p>8.5. The use of any of Our interactive services by a minor is subject to the consent of their parent or guardian. We advise parents who permit their children to use an interactive service that it is important that they communicate with their children about their safety online, as moderation is not fool proof. Minors who are using any interactive service should be made aware of the potential risks to them.</p>
        <p>8.6. Where we do moderate an interactive service, we will normally provide you with a means of contacting the moderator, should a concern or difficulty arise.</p>
        <p><b>9. Content Standards</b></p>
        <p>9.1. From time to time we may allow users and visitors to Our Site to submit materials and content (Contributions). These content standards apply to any and Contributions that you may be able to contribute to Our Site and to any interactive services associated with it.</p>
        <p>9.2. The Content Standards must be complied with in spirit as well as to the letter. The standards apply to each part of any Contribution as well as to its whole.</p>
        <p>9.3. We will determine, in its discretion, whether a Contribution breaches the Content Standards.</p>
        <p>9.4. A Contribution must:</p>
        <p>9.4.1. be accurate (where it states facts); and</p>
        <p>9.4.2. be genuinely held (where it states opinions).</p>
        <p>9.5. A Contribution must not;</p>
        <p>9.5.1. be defamatory of any person, be obscene, offensive, hateful or inflammatory, promote sexually explicit material, promote or encourage violence, promote discrimination based on race, sex, religion, nationality, disability, sexual orientation or age;</p>
        <p>9.5.2. infringe any copyright, database right or trade mark of any other person;</p>
        <p>9.5.3. be likely to deceive any person by any method including impersonation or misrepresentation of any kind;</p>
        <p>9.5.4. breach any legal duty owed to a third party, such as a contractual duty or a duty of confidence or promote any illegal activity, be made in contempt of court;</p>
        <p>9.5.5. be threatening, abusive to or invade another individual’s privacy, or cause annoyance, inconvenience or needless anxiety, nor shall it be likely to harass, upset, embarrass, alarm or annoy any other person;</p>
        <p>9.5.6. give the impression that the Contribution emanates from Synectics plc or any of its’ group company, if this is not the case;</p>
        <p>9.5.7. advocate, promote, incite any party to commit, or assist any unlawful or criminal act such as (by way of example only) copyright infringement or computer misuse;</p>
        <p>9.5.8. contain a statement which you know or believe, or have reasonable grounds for believing, that members of the public to whom the statement is, or is to be, published are likely to understand as a direct or indirect encouragement or other inducement to the commission, preparation or instigation of acts of terrorism; or</p>
        <p>9.5.9. contain any advertising or promote any services or web links to other sites.</p>
        <p><b>10. Breach of this Policy</b></p>
        <p>10.1. When we consider that a breach of this Policy has occurred, we may take such action as we deem appropriate.</p>
        <p>10.2. Failure to comply with this Policy constitutes a material breach of the Terms of Use upon which you are permitted to use Our Site, and may result in our taking all or any of the following actions:</p>
        <p>10.2.1. immediate, temporary or permanent withdrawal of your right to use Our Site;</p>
        <p>10.2.2. immediate, temporary or permanent removal of any Contribution uploaded by you to Our Site.</p>
        <p>10.2.3. issue of a warning to you;</p>
        <p>10.2.4. legal proceedings against you for reimbursement of all costs on an indemnity basis (including, but not limited to, reasonable administrative and legal costs) resulting from the breach;</p>
        <p>10.2.5. further legal action against you; and</p>
        <p>10.2.6. disclosure of such information to law enforcement authorities as We reasonably feel is necessary or as required by law.</p>
        <p>10.3. We exclude our liability for all action We may take in response to breaches of this Policy, save where we are not permitted to exclude or limit our liability according to the law of England and Wales. The actions we may take are not limited to those described above, and we may take any other action we reasonably deem appropriate.</p>
        <p><b>11. Applicable Law and Jurisdiction</b></p>
        <p>11.1. All of the documents comprising the Policy, their subject matter and their formation, and any claim or dispute arising out of or connection with them shall be governed by the laws of England and Wales.</p>
        <p>11.2. The courts of England and Wales will have exclusive jurisdiction over any dispute or claim arising out of or in connection with the Policy.</p>
    </div>

</div>
