import {Component, OnInit} from "@angular/core";

@Component({
    selector: "cookie-policy",
    templateUrl: "./cookie-policy.component.html",
    styleUrls: ["./cookie-policy.component.scss"]
})
export class CookiePolicyComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
