import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule, ErrorHandler } from '@angular/core';
import { AppComponent } from './app.component';
import { Router, RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routes';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpErrorService } from './services/http-error.service';
import { ToastrModule } from 'ngx-toastr';
import { CoreModule } from './core/core.module';
import { ApmErrorHandler, ApmModule, ApmService } from '@elastic/apm-rum-angular';
import { CommonModule } from '@angular/common';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ConfigService } from './services/config/config.service';
import { CustomerManagementService } from './services/customer-management/customer-management.service';
import { ConfirmDialogComponent, ConfirmDialogComponentDialog } from './confirm-dialog/confirm-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';


export function initConfig(config: ConfigService) {
  return () => {
    return config.load();
  };
}

@NgModule({
  declarations: [AppComponent,
    ConfirmDialogComponent,
    ConfirmDialogComponentDialog],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    RouterModule,
    CommonModule,
    HttpClientModule,
    ApmModule,
    CoreModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    MatDialogModule
  ],
  providers: [
    ConfigService,
    CustomerManagementService,
    HttpErrorService,
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      multi: true,
      deps: [ConfigService],
    },
    // {
    //   provide: ErrorHandler,
    //   useClass: ApmErrorHandler,
    //   deps: [Router],
    // },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },

  ],
  entryComponents: [ConfirmDialogComponentDialog],
  bootstrap: [AppComponent],
})
export class AppModule {
  // constructor(service: ApmService) {
  //   // API is exposed through this apm instance
  //   const apm = service.init({
  //     serviceName: 'DeviceManagement',
  //     serverUrl: 'https://apmserver.insights.synxcloud.com/',
  //   });
  //   apm.setUserContext({
  //     username: 'foo',
  //     id: 'bar',
  //   });
  // }
}
