import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ConfigService {
  private config;

  // Getters
  get API_URI() {
    return this.getProperty('API_URI');
  }

  constructor(private http: HttpClient) {}

  load(): Promise<any> {
    const promise = this.http.get('./assets/environment.txt').toPromise();

    // Sets config then returns promise to APP_INITIALISER
    promise.then((config) => {
      this.config = config;
    });
    return promise;
  }

  private getProperty(property: string): any {
    if (!this.config) {
      console.error(`No Config data loaded`);
      return;
    }

    if (!this.config[property]) {
      console.error(`Cannot find config property ` + property);
      return;
    }

    return this.config[property];
  }
}
