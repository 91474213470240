import {Component, OnInit} from "@angular/core";

@Component({
    selector: "terms-of-use",
    templateUrl: "./terms-of-use.component.html",
    styleUrls: ["./terms-of-use.component.scss"]
})
export class TermsOfUseComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
