import {Component, OnInit} from "@angular/core";
import {OAuthService} from "angular-oauth2-oidc";
import {Router} from "@angular/router";

@Component({
    selector: "app-header",
    templateUrl: "./app-header.component.html",
    styleUrls: ["./app-header.component.scss"]
})
export class AppHeaderComponent implements OnInit {

    /**
     * CTOR
     * @param oAuthService
     * @param router
     */
    constructor(private oAuthService: OAuthService,
                public router: Router) {
    }

    /**
     * On Init
     */
    ngOnInit() {
    }

    /**
     * A function to navigate the user to the home screen.
     */
    gotoHome() {
        this.router.navigate(["landing"]);
    }

    /**
     * A Function to navigate the user to the admin page.
     */
    gotoAdmin() {
        this.router.navigate(["admin/admin-landing"]);
    }

    /**
     * A Function to navigate the user to the profile page.
     */
    gotoProfile() {
        this.router.navigate(["profile"]);
    }

    /**
     * Makes a call to identityserver to logout
     */
    logout() {
        this.oAuthService.logOut();
    }

    /**
     * A Function to navigate the user to the global settings page.
     */
    gotoGlobalSettings() {
        this.router.navigate(["global-settings"]);
    }
}
