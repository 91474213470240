<div
    class="footer text-center mx-auto mt-4 mb-3"
    [class.extended-margin]="extendedMargin == true"
>
    <div class="links d-block mb-3">
        <a (click)="gotoFooterPage('privacy-policy')">Privacy Policy</a>
        <a (click)="gotoFooterPage('cookie-policy')">Cookie Policy</a>
        <a (click)="gotoFooterPage('terms-of-use')">Terms of Use</a>
        <a (click)="gotoFooterPage('acceptable-use-policy')">
            Acceptable Use Policy
        </a>
        <a (click)="gotoFooterPage('disclaimer')">Disclaimer</a>
    </div>
    <small class="copyright d-block">
        Copyright © 2023 Synectic Systems Group Limited. All rights reserved.
        Synectic Systems Group Limited is a company registered in England and
        Wales with company number 05815524. Registered office: Synectics House,
        3-4 Broadfield Close, Sheffield, S8 0XN, United Kingdom
    </small>
</div>
