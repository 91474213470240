<div class="card p-3">

    <h1 class="mb-4">Privacy Notice</h1>

    <div class="content">
        <p><em>Synectics plc, owner of www.synxcloud.com</em></p>
        <p><b>Introduction</b></p>
        <p>Synectics plc (and its subsidiary companies) respects your privacy and is committed to protecting your personal data. This Notice will inform you as to how we look after your personal data when you visit our website (regardless of where you visit it from) and tell you about your privacy rights and how the law protects you.</p>
        <p>This Notice is available here for you to read. Please also use the Glossary (below) to understand the meaning of some of the terms used in this Notice.</p>
        <p><b>1. Purpose of this Privacy Notice</b></p>
        <p>1.1. This Notice gives you information on how we collect and process your personal data through your use of this website.</p>
        <p>1.2. This website is not intended for minors and we do not knowingly collect data relating to children.</p>
        <p>1.3. It is important that you read this Notice together with any other privacy notice we may provide on specific occasions when we are collecting or processing personal data about you so that you are fully aware of how and why we are using your data. This Notice supplements the other notices and is not intended to override them.</p>
        <p><b>Data Controller</b></p>
        <p>1.4. Synectics plc is the data controller and responsible for your personal data that we obtain when you visit this website. Synectics plc is the parent company within a group companies which is made up of different legal entities (Group) (“we” “us” “our”). This Notice is issued on behalf of the Group so when we mention “we”, “us” or “our” in this Notice, we are referring to the relevant company in the Group responsible for processing your data. If necessary we will let you know if another entity will be the controller of your data.</p>
        <p>1.5. We have appointed a data protection officer (DPO) who is responsible for overseeing questions in relation to this Notice. If you have any questions about this Notice, including any requests to exercise any of your rights in respect of data protection, please contact the DPO using the details set out below.</p>
        <p>1.6. You have the right to make a complaint at any time to the Information Commissioner’s Office (ICO), the UK supervisory authority for data protection issues (www.ico.org.uk). We would, however, appreciate the chance to deal with your concerns before you approach the ICO so please contact us in the first instance.</p>
        <p><b>Contact Details</b></p>
        <p>Legal entity: Synectics plc<br>Name or title of DPO: The Company Secretary of Synectics plc<br>Email address: <a href="mailto:legalandsecretarial@synecticsplc.com"><span class="cloaked_email">legalandsecretarial@synecticsplc.com</span></a><script type="text/javascript">emailProtector.addCloakedMailto("ep_0167f2ba", 1);</script><br>Postal address: Studley Point, 88 Birmingham Road, Studley, Warwickshire B80 7AS</p>
        <p><b>Changes to the Privacy Notice and Your Duty to Inform Us of Changes</b></p>
        <p>1.7. This version has been approved by our Group. It was approved on 24 May 2018.</p>
        <p>1.8. It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us.</p>
        <p>1.9. This website may include links to third party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third party websites and are not responsible for their websites or their privacy statements. When you leave our website, we encourage you to read the privacy notice of every website you visit.</p>
        <p><b>2. The Data We Collect about You</b></p>
        <p>2.1. ‘Personal Data’, or personal information, means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data).</p>
        <p>2.2. We may collect, use, store and transfer different kinds of Personal Data about you which we have grouped together as follows:</p>
        <p>2.2.1. Identity Data which may include your first name, last name, a profile username or date of birth;</p>
        <p>2.2.2. Contact Data which may include your email address or contact telephone number;</p>
        <p>2.2.3. Technical Data which may include your internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform and other technology on the devices you use to access this website;</p>
        <p>2.2.4. Profile Data which may include a username and password;</p>
        <p>2.2.5. Usage Data which may include information about how you use our website, products and services; and</p>
        <p>2.2.6. Marketing and Communications Data which includes your preferences in receiving marketing from us and our third parties and your communication preferences.</p>
        <p>2.3. We may also collect, use and share Aggregated Data such as statistical or demographic data for any purpose. Aggregated Data may be derived from your personal data but is not considered personal data in law as this data does not directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific website page. However, if we combine or connect Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this Notice.</p>
        <p>2.4. We do not collect any Special Categories of Personal Data about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health and genetic and biometric data). Nor do we collect any information about criminal convictions and offences.</p>
        <p><b>If You Fail to Provide Personal Data </b></p>
        <p>2.5. Where we need to collect personal data by law, or under the terms of a contract we have with you and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you. In this case, we may have to cancel a product or service you have with us but we will notify you if this is the case at the time.</p>
        <p><b> 3. How is Your Personal Data Collected? </b></p>
        <p>3.1. We use different methods to collect data from and about you. For example:</p>
        <p>3.1.1. Direct interactions with you. You may provide us with your personal data by filling in forms or by corresponding with us by post, phone, email or otherwise. This includes personal data you provide when you:</p>
        <p>3.1.1.1. subscribe to our service or publications;</p>
        <p>3.1.1.2. request marketing to be sent to you;</p>
        <p>3.1.1.3. enter a competition, promotion or survey; or</p>
        <p>3.1.1.4. give us some feedback;</p>
        <p>3.1.2. Automated technologies or interactions. As you interact with our website, we may automatically collect Technical Data about your equipment, browsing actions and patterns. We collect this personal data by using cookies, server logs and other similar technologies. We may also receive Technical Data about you if you visit other websites employing our cookies. Please see our <a href="/cookie-policy" target="_blank" rel="noopener noreferrer">cookie policy</a> for further details;</p>
        <p>3.1.3. Third parties or publicly available sources. We may receive personal data about you from various third parties;</p>
        <p>3.1.4. Technical Data from the following parties:</p>
        <p>3.1.4.1. analytics providers such as Google based outside the EU; and</p>
        <p>3.1.4.2. search information providers such as Google based inside the EU;</p>
        <p>3.1.5. Contact and Transaction Data from providers of technical, payment and delivery services based inside the EU; and</p>
        <p>3.1.6. Identity and Contact Data from third party sources (for example Companies House).</p>
        <p><b>4. How We Use Your Personal Data</b></p>
        <p>4.1. We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:</p>
        <p>4.1.1. Where we need to perform the contract we are about to enter into or have entered into with you;</p>
        <p>4.1.2. Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests; and</p>
        <p>4.1.3. Where we need to comply with a legal or regulatory obligation.</p>
        <p>4.2. Generally we do not rely on consent as a legal basis for processing your personal data other than in relation to sending third party direct marketing communications to you via email or text message. You have the right to withdraw consent to marketing at any time. To do this, please contact our DPO.</p>
        <p><b>Purposes for Which We Will Use Your Personal Data</b></p>
        <p>4.3. We have set out below, in a table format, a description of all the ways we plan to use your personal data, and which of the legal bases we rely on to do so. We have also identified what our legitimate interests are where appropriate.</p>
        <p>4.4. Note that we may process your personal data for more than one lawful ground depending on the specific purpose for which we are using your data. Please contact us if you need details about the specific legal ground we are relying on to process your personal data where more than one ground has been set out in the table below.</p>
        <div class="table-responsive table-block">
            <table border="1">
                <thead>
                <tr>
                    <th style="width: 33%;" align="left">Purpose/Activity</th>
                    <th style="width: 33%;" align="left">Type of data</th>
                    <th style="width: 33%;" align="left">Lawful basis for processing including basis of legitimate interest</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>To register you as a new customer</td>
                    <td>(a) Identity<br>(b) Contact</td>
                    <td>Performance of a contract with you</td>
                </tr>
                <tr>
                    <td>To manage our relationship with you which will include:<br>(a) Notifying you about changes to our terms or privacy policy<br>(b) Asking you to leave a review or take a survey</td>
                    <td>(a) Identity<br>(b) Contact<br>(c) Profile<br>(d) Marketing and Communications</td>
                    <td>(a) Performance of a contract with you<br>(b) Necessary to comply with a legal obligation<br>(c) Necessary for our legitimate interests (to keep our records updated and to study how customers use our products/services)</td>
                </tr>
                <tr>
                    <td>To enable you to complete a survey</td>
                    <td>(a) Identity<br>(b) Contact<br>(c) Profile<br>(d) Usage<br>(e) Marketing and Communications</td>
                    <td>(a) Performance of a contract with you<br>(b) Necessary for our legitimate interests (to study how customers use our products/services, to develop them and grow our business)</td>
                </tr>
                <tr>
                    <td>To administer and protect our business and this website (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data)</td>
                    <td>(a) Identity<br>(b) Contact<br>(c) Technical</td>
                    <td>(a) Necessary for our legitimate interests (for running our business, provision of administration and IT services, network security, to prevent fraud and in the context of a business reorganisation or group restructuring exercise)<br>(b) Necessary to comply with a legal obligation</td>
                </tr>
                <tr>
                    <td>To deliver relevant website content and advertisements to you and measure or understand the effectiveness of the advertising we serve to you</td>
                    <td>(a) Identity<br>(b) Contact<br>(c) Profile<br>(d) Usage<br>(e) Marketing and Communications<br>(f) Technical</td>
                    <td>Necessary for our legitimate interests (to study how customers use our products/services, to develop them, to grow our business and to inform our marketing strategy)</td>
                </tr>
                <tr>
                    <td>To use data analytics to improve our website, products/services, marketing, customer relationships and experiences</td>
                    <td>(a) Technical<br>(b) Usage</td>
                    <td>Necessary for our legitimate interests (to define types of customers for our products and services, to keep our website updated and relevant, to develop our business and to inform our marketing strategy)</td>
                </tr>
                <tr>
                    <td>To make suggestions and recommendations to you about goods or services that may be of interest to you</td>
                    <td>(a) Identity<br>(b) Contact<br>(c) Technical<br>(d) Usage<br>(e) Profile</td>
                    <td>Necessary for our legitimate interests (to develop our products/services and grow our business)</td>
                </tr>
                </tbody>
            </table>
        </div>
        <p><b>Third Party Marketing</b></p>
        <p>4.5. We will get your express opt-in consent before we share your personal data with any company outside the Group for marketing purposes.</p>
        <p><b>Opting Out</b></p>
        <p>4.6. You can ask us or third parties to stop sending you marketing messages at any time by following the opt-out links on any marketing message sent to you.</p>
        <p><b>Cookies</b></p>
        <p>4.7. You can set your internet browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of this website may become inaccessible or not function properly. For more information about the cookies we use, please see our <a href="/cookie-policy" target="_blank" rel="noopener noreferrer">cookie policy</a>.</p>
        <p><b>Change of Purpose</b></p>
        <p>4.8. We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible with the original purpose, please contact us.</p>
        <p>4.9. If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so.</p>
        <p>4.10. Please note that we may process your personal data without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.</p>
        <p><b>5. Disclosures of Your Personal Data</b></p>
        <p>5.1. We may have to share your personal data with the parties set out below for the purposes set out in the table in paragraph 4 above;</p>
        <p>5.1.1. Internal Third Parties as set out in part 10 (Glossary);</p>
        <p>5.1.2. External Third Parties as set out in part 10 (Glossary);</p>
        <p>5.1.3. Specific third parties such as Google; and</p>
        <p>5.1.4. Third parties to whom we may choose to sell, transfer, or merge parts of our business or our assets.</p>
        <p>5.2. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this Notice.</p>
        <p>5.3. We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We do not allow our third party service providers to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions.</p>
        <p><b>6. International Transfers</b></p>
        <p>6.1. We may share your personal data within our Group. This can involve transferring your data outside the European Economic Area (EEA).</p>
        <p>6.2. We ensure your personal data is protected by requiring all companies in our Group to follow the same rules when processing your personal data. We achieve this through a binding Intra Group Data Transfer Agreement, which has been signed by all companies in our Group.</p>
        <p>6.3. Whenever we transfer your personal data out of the EEA, we ensure the same protection is afforded to it by ensuring that specific contract clauses approved by the European Commission which give personal data the same protection it has in Europe.</p>
        <p>6.4. Please contact us if you want further information on the specific mechanism used by us when transferring your personal data out of the EEA.</p>
        <p><b>7. Data Secruity</b></p>
        <p>7.1. We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality.</p>
        <p>7.2. We have put in place procedures to deal with any suspected personal data breach (Data Breach Policy) and will notify you and any applicable regulator of a breach where we are legally required to do so.</p>
        <p><b>8. Data Rentention</b></p>
        <p>8.1. We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.</p>
        <p>8.2. Generally to determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.</p>
        <p><b>9. Your Legal Rights</b></p>
        <p>9.1. Under certain circumstances, you have rights under data protection laws in relation to your personal data. You have the right to:</p>
        <p>9.1.1. request access to your personal data;</p>
        <p>9.1.2. request the correction of your personal data;</p>
        <p>9.1.3. request the erasure of your personal data;</p>
        <p>9.1.4. object to the processing of your personal data;</p>
        <p>9.1.5. request the restriction of processing your personal data;</p>
        <p>9.1.6. request transfer of your personal data; and</p>
        <p>9.1.7. withdraw your consent to the processing of any part of your personal data.</p>
        <p>9.2. If you wish to exercise any of the rights set out above, please contact the DPO.</p>
        <p><b>No Fee Ususall Required</b></p>
        <p>9.3. You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may also refuse to comply with your request in these circumstances.</p>
        <p><b>What We May Need from You </b></p>
        <p>9.4. We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.</p>
        <p><b>Time Limit to Respond</b></p>
        <p>9.5. We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.</p>
        <p><b>Glossary</b></p>
        <p>10.1. The following words and / or phrases used in this Policy shall have the following meanings;</p>
        <p>Legitimate Interest means the interest of our business in conducting and managing our business to enable us to give you the best service/product and the best and most secure experience. We make sure we consider and balance any potential impact on you (both positive and negative) and your rights before we process your personal data for our legitimate interests. We do not use your personal data for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law). You can obtain further information about how we assess our legitimate interests against any potential impact on you in respect of specific activities by contacting us.</p>
        <p>Performance of Contract means processing your data where it is necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract.</p>
        <p>Comply with a legal or regulatory obligation means processing your personal data where it is necessary for compliance with a legal or regulatory obligation that we are subject to.</p>
        <p>Internal Third Parties means other companies in the Group who are based in Germany, The United States of America, the United Arab Emirates, Macau or Singapore.</p>
        <p>External Third Parties means (i) professional advisers including lawyers, bankers, auditors and insurers who provide consultancy, banking, legal, insurance and accounting services and / or (ii) HM Revenue &amp; Customs, regulators and other authorities acting as processors or joint controllers based in the United Kingdom who require reporting of processing activities in certain circumstances.</p>
    </div>

</div>
