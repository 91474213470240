import {Injectable} from "@angular/core";
import {ToastrService} from "ngx-toastr";
import {HttpErrorResponse} from "@angular/common/http";

@Injectable({
    providedIn: "root"
})
export class HttpErrorService {

    constructor(private toastrService: ToastrService) {
    }

    handleHttpError(error: HttpErrorResponse | any) {
        if (error == null) return;

        // Status 0: Unknown Error
        if (error.status == 0) {
            this.toastrService.error(error.statusText, "Error Code: " + error.status);
        }

        // Status 400: Bad Request
        else if (error.status == 400) {
            if (error.error && error.error.error) {
                this.toastrService.error(error.error.error.details[0].message, "Error Code: " + error.status);
            } else {
                this.toastrService.error(error.statusText, "Error Code: " + error.status);
            }
        }

        // Status 401: Unauthorised
        else if (error.status == 401) {
            this.toastrService.error(error.statusText, "Error Code: " + error.status);
        }

        // Status 403: Forbidden
        else if (error.status == 403) {
            this.toastrService.error(error.statusText, "Error Code: " + error.status);
        }

        // Status 404: Not Found
        else if (error.status == 404) {
            this.toastrService.error(error.statusText, "Error Code: " + error.status);
        }

        // Status 500: Internal Server Error
        else if (error.status == 500) {
            this.toastrService.error(error.statusText, "Error Code: " + error.status);
        }

        // Timeout error
        else if (error.name == "TimeoutError") {
            this.toastrService.error("Server did not respond to the request within the required time", "Timeout");
        }

        else {
            this.toastrService.error(error.statusText, "Unknown Error!");
        }
    }

}
