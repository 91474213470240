<ng-template #modal>
    <div class="modal-top px-3 pt-3">
        <div class="top-row d-flex flex-row justify-content-between">
            <h3 class="d-inline text-capitalize"><i *ngIf="fa-icon" class="fas mr-3 {{fa-icon}}"></i>{{headerTitleText}}</h3>
            <div class="btn-container">
                <button class="help-icon" *ngIf="helpId != null" (click)="displayHelp(helpId)">
                    <i class="fas fa-question-circle"></i>
                </button>
                <button class="close-icon ml-3" *ngIf="static == false" type="button" (click)="closeModal()">
                    <i class="fas fa-times fa-lg"></i>
                </button>
            </div>
        </div>
    </div>


    <div class="modal-middle d-flex flex-grow-1 flex-column px-3 pb-3">
        <ng-content select="[modal-inner]"></ng-content>
    </div>
</ng-template>
