import {Component, OnInit} from "@angular/core";
import {Router, NavigationEnd} from "@angular/router";
import { filter } from "rxjs/operators";


@Component({
    selector: "app-footer",
    templateUrl: "./app-footer.component.html",
    styleUrls: ["./app-footer.component.scss"]
})
export class AppFooterComponent implements OnInit {

    extendedMargin = false;

    constructor(private router: Router) {
    }

    ngOnInit() {
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((routeEvent: NavigationEnd) => {

                // If on a page with fixed controls, add a class to template to add extra margin so footer is still displayed.
                if (routeEvent.url == "/incident/face-redactor" || routeEvent.url == "/incident/clip-grid") {
                    this.extendedMargin = true;
                }
                else {
                    this.extendedMargin = false;
                }
            });
    }

    gotoFooterPage(pageRoute: string) {
        this.router.navigate([pageRoute]);
    }

}
