import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { ConfigService } from '../config/config.service';
import { HttpErrorService } from '../http-error.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Customer, PagerOptions } from '../../app.types';
import { stringifyPagerOptions } from '../http-helpers';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class CustomerManagementService {
  cachedPagerOptions: PagerOptions;

  /**
   * CTOR
   * @param {HttpClient} http
   * @param {ConfigService} configService
   * @param {HttpErrorService} httpErrorService
   * @param toastrService
   */
  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private httpErrorService: HttpErrorService,
    private toastrService: ToastrService
  ) {}

  /**
   * Gets all customers
   * @param {PagerOptions} opts
   * @returns {Observable<any>}
   */
  getCustomers(opts: PagerOptions) {
    let url = this.configService.API_URI + '/Customers';

    // Stringify pagerOptions
    const optionsString = stringifyPagerOptions(opts);
    if (optionsString !== null) url += optionsString;

    const req = new HttpRequest('GET', url);

    return this.http.request(req).pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => {
        this.httpErrorService.handleHttpError(err);
        return of(err);
      })
    );
  }

  /**
   * Adds a customer
   * @returns {Observable<any>}
   * @param customer
   */
  addCustomer(customer: Customer) {
    let url = this.configService.API_URI + '/Customers';

    const req = new HttpRequest('POST', url, customer);

    return this.http.request(req).pipe(
      map((res) => {
        if (res instanceof HttpResponse) {
          this.toastrService.success('Customer added.', 'Success');
          return res;
        }
      }),
      catchError((err) => {
        this.httpErrorService.handleHttpError(err);
        return of(err);
      })
    );
  }

  /**
   * Edits a customer
   * @returns {Observable<any>}
   * @param customer
   */
  editCustomer(customer: Customer) {
    let url = this.configService.API_URI + '/Customers/' + customer.id;

    const req = new HttpRequest('PUT', url, customer);

    return this.http.request(req).pipe(
      map((res) => {
        if (res instanceof HttpResponse) {
          this.toastrService.success('Customer updated.', 'Success');
          return res;
        }
      }),
      catchError((err) => {
        this.httpErrorService.handleHttpError(err);
        return of(err);
      })
    );
  }

  /**
   * Delete a customer
   * @returns {Observable<any>}
   * @param customer
   */
  deleteCustomer(customer: Customer) {
    let url = this.configService.API_URI + '/Customers/' + customer.id;

    const req = new HttpRequest('DELETE', url);

    return this.http.request(req).pipe(
      map((res) => {
        if (res instanceof HttpResponse) {
          this.toastrService.success('Customer deleted.', 'Success');
          return res;
        }
      }),
      catchError((err) => {
        this.httpErrorService.handleHttpError(err);
        return of(err);
      })
    );
  }
}
