/// <reference path="../../../../node_modules/@types/bootstrap/index.d.ts" />

import {
    Component,
    ChangeDetectionStrategy,
    ViewChild,
    Input,
    EventEmitter,
    Output,
    AfterViewInit,
    TemplateRef,
    OnDestroy,
} from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";

@Component({
    selector: "modal",
    templateUrl: "./modal.component.html",
    styleUrls: ["./modal.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent implements AfterViewInit, OnDestroy {
    modalInstance: NgbModalRef;

    @ViewChild("modal") modalContent: TemplateRef<any>;
    @Input() modalId: string;
    @Input() modalSize: "sm" | "lg" = null;
    @Input() headerTitleText = "";
    @Input() static = false;
    @Input() helpId: string = null;
    @Output() close: EventEmitter<void> = new EventEmitter();

    ngUnsubscribe$ = new Subject<boolean>();

    /**
     * Constructor
     */
    constructor(private modalService: NgbModal) {}

    /**
     * Initialize component.
     */
    ngAfterViewInit(): void {
        window.setTimeout(() => {
            let modalOpts = null;
            if (this.static == true) {
                modalOpts = {
                    size: this.modalSize,
                    backdrop: "static",
                    centered: true,
                };
            } else {
                modalOpts = {
                    size: this.modalSize,
                };
            }

            this.modalInstance = this.modalService.open(
                this.modalContent,
                modalOpts
            );
            this.modalInstance.result
                .then(() => {
                    this.closeModal();
                })
                .catch(() => {
                    this.closeModal();
                });
        }, 50);
    }

    /**
     * Cleanup
     */
    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    /**
     * Closes the modal
     */
    closeModal() {
        this.close.emit();
        this.modalInstance.close();
    }

    /**
     * A function to open the help modal
     * @param helpId
     */
    // displayHelp(helpId: string) {
    //     this.modalActions.setHelpModalData(helpId);
    //     this.modalActions.openModal("help-modal");
    // }
}
