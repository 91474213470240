import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

export interface DialogData {
    message: "";
  }

@Component({
  selector: 'confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {

  constructor(public dialog: MatDialog) { }

  openDialog() {
    const dialogRef = this.dialog.open(ConfirmDialogComponentDialog, {
        data: {
            message: "Are you wish you want to delete?"
          }
        });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}

@Component({
    selector: 'confirm-dialog-dialog',
    templateUrl: 'confirm-dialog-dialog.html'
  })
  export class ConfirmDialogComponentDialog {

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}
  }