import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";

@Component({
    selector: "kebab-menu",
    templateUrl: "./kebab-menu.component.html",
    styleUrls: ["./kebab-menu.component.scss"]
})
export class KebabMenuComponent implements OnInit {

    @Output() addFunction: EventEmitter<null> = new EventEmitter();
    @Input() disableAddFunction: boolean;

    /**
     * CTOR
     */
    constructor() {
    }

    /**
     * On Init
     */
    ngOnInit() {
    }

}
