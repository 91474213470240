<div class="container">
    <nav class="navbar px-3 mt-sm-3 mb-4 d-flex justify-content-between">
        <div class="navbar-brand">
            <a href="http://www.synecticsglobal.com" class="d-inline-block" alt="Synectics">
                <img class="logo" src="../../assets/logo-white.png"/>
            </a>

        </div>
        <div class="mt-3 pt-1">
            <h3 class="d-none d-md-inline-block py-2 pr-4">Cloud Services</h3>

            <div class="d-inline-block util-btns pl-sm-4">
                <a class="home-btn" (click)="gotoHome()">
                    <i class="fas fa-home"></i>
                </a>
                <a class="user-btn" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                   aria-expanded="false">
                    <i class="fas fa-user"></i>
                </a>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <a class="dropdown-item username">You are logged in as XXX.</a>
                    <a class="dropdown-item font-weight-bold" (click)="logout()">Logout</a>
                </div>
            </div>
        </div>
    </nav>
</div>
