import {HttpHeaders} from "@angular/common/http";
import {PagerOptions} from "../app.types";

export const requestSettings = {
    httpOptions: {
        headers: new HttpHeaders({
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
            "Pragma": "no-cache"
        })
    },
    timeoutPeriod: 30000
};

export const stringifyPagerOptions = (opts: PagerOptions) => {
    const optionsArray: string[] = [];

    if (opts.pageSize != null) optionsArray.push("pageSize=" + opts.pageSize);
    if (opts.page != null) optionsArray.push("page=" + opts.page);
    if (opts.search != null) optionsArray.push("search=" + opts.search.type + "=" + opts.search.searchString.toLowerCase());
    if (opts.filter != null) optionsArray.push("filter=" + opts.filter.type + "=" + opts.filter.filterString);
    if (opts.orderBy != null) optionsArray.push("orderBy=" + opts.orderBy);
    if (opts.ascending != null) optionsArray.push("ascending=" + opts.ascending);

    const optionsString = "?" + optionsArray.join("&");

    return optionsString;
};
