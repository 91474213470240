import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ModalComponent} from "./modal/modal.component";
import {AppHeaderComponent} from "./app-header/app-header.component";
import {AppFooterComponent} from "./app-footer/app-footer.component";
import {AcceptableUsePolicyComponent} from "./app-footer/footer-pages/acceptable-use-policy/acceptable-use-policy.component";
import {CookiePolicyComponent} from "./app-footer/footer-pages/cookie-policy/cookie-policy.component";
import {DisclaimerComponent} from "./app-footer/footer-pages/disclaimer/disclaimer.component";
import {PrivacyPolicyComponent} from "./app-footer/footer-pages/privacy-policy/privacy-policy.component";
import {TermsOfUseComponent} from "./app-footer/footer-pages/terms-of-use/terms-of-use.component";
import {KebabMenuComponent} from "./kebab-menu/kebab-menu.component";

@NgModule({
    declarations: [
        AppHeaderComponent,
        AppFooterComponent,
        AcceptableUsePolicyComponent,
        CookiePolicyComponent,
        DisclaimerComponent,
        PrivacyPolicyComponent,
        TermsOfUseComponent,
        ModalComponent,
        KebabMenuComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        AppHeaderComponent,
        AppFooterComponent,
        ModalComponent,
        KebabMenuComponent
    ]
})
export class CoreModule {
}
